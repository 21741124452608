// module.exports={ 
// "anypoint" : "http://188.166.168.44:8089",
// "domain" : "http://localhost:8081",
// "ENDPOINT" :"http://localhost:8081",
// "SOCKET":"http://localhost:8083",
// "socketUrl":"localhost"
// }
// module.exports={ 
//     "anypoint" : "http://188.166.168.44:8089",
//     "domain" : "https://188.166.168.44:443/controller",
//     "ENDPOINT" :"https://188.166.168.44:443/controller",
//     "SOCKET":"https://188.166.168.44:443/socket.io",
// "socketUrl":"188.166.168.44"

// }
module.exports={
    "anypoint" : "http://qa.istika.njclabs.com:8089",
    "domain" : "https://qa.istika.njclabs.com/controller",
    "ENDPOINT" :"https://qa.istika.njclabs.com/controller",
    "SOCKET":"https://qa.istika.njclabs.com:443"
}